import http from "../../node_modules/fe-infrastractures/src/utils/http";
import { globalEnv } from "fe-infrastractures";

export const getTenantType = () => {
  return http.get("/api/enum/EnumTenantType", {}, { loading: false, baseURL: globalEnv.VUE_APP_API_URL });
};

export const getTimeZone = () => {
  return http.get("/api/enum/EnumTimeZone", {}, { loading: false, baseURL: globalEnv.VUE_APP_API_URL });
};

export const getApiProjectType = () => {
  return http.get("/api/enum/EnumApiProjectType", {}, { loading: false, baseURL: globalEnv.VUE_APP_API_URL });
};

export const getDateFormat = () => {
  return http.get("/api/enum/EnumDateFormat", {}, { loading: false, baseURL: globalEnv.VUE_APP_API_URL });
};

export const getYesNo = () => {
  return http.get("/api/enum/yes-no", {}, { loading: false, baseURL: globalEnv.VUE_APP_STD_URL });
};
export const getElectionStatus = () => {
  return http.get("/api/enum/election-status", {}, { loading: false, baseURL: globalEnv.VUE_APP_STD_URL });
};

export const getUnsubscribeReasonType = (data) => {
  return http.get("/anon/enum/unsubscribe-reason-type", data, { loading: false, baseURL: globalEnv.VUE_APP_STD_URL });
}