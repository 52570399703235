import http from "../../node_modules/fe-infrastractures/src/utils/http";
import { globalEnv } from "fe-infrastractures";

export const saveUnsubscribeDetailByEmail = (data) => {
  return http.post("/anon/unsubscribe/email/save", data, { useBody: true });
};

export const checkEmail = (data) => {
  return http.post("/anon/unsubscribe/email/check-email", data, {
    systemPATH: "admin",
    loading: false,
    useBody: true,
    baseURL: globalEnv.VUE_APP_ADMIN_URL,
  });
};