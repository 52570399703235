<template>
  <div>
    <KValidationObserver ref="observer" class="content-page">
      <k-form
        class="content-form"
        @keydown.enter="isPrevent"
        @submit.prevent="onSubmit"
        v-show="showForm"
      >
        <div class="content-main">
          <k-alert-msg ref="lblMsg" />
          <div class="content-party p-3">
            <div class="content-group p-4" style="background-color: #fff">
              <k-form-group
                label-suffix="unsubscribeReason"
                label="unsubscribeReason"
                label-align-md="right"
                label-cols-md="3"
                content-cols-lg="6"
                content-cols-md="7"
                class="d-block mt-3"
              >
                <KValidationProvider
                  :rules="{ required: false }"
                  v-slot="{ errors }"
                  name="reasonType"
                >
                  <k-alert :show="errors.length > 0" variant="warning">
                    {{ errors[0] }}
                  </k-alert>
                  <b-form-radio-group
                    v-model="form.reasonType"
                    class="mt-lg-2"
                    :options="reasonTypeOptions"
                    stacked
                  />
                </KValidationProvider>
              </k-form-group>
              <k-form-group
                label-align-md="right"
                label-cols-md="3"
                content-cols-lg="6"
                content-cols-md="7"
                v-show="form.reasonType === -1"
              >
                <KValidationProvider
                  :rules="{ required: false, max: 255 }"
                  v-slot="{ errors }"
                  name="reason"
                >
                  <k-alert :show="errors.length > 0" variant="warning">
                    {{ errors[0] }}
                  </k-alert>
                  <k-form-textarea
                    id="unsubscribeReasonOther"
                    v-model="form.reason"
                  />
                </KValidationProvider>
              </k-form-group>
              <k-form-group
                label-align-md="right"
                label-cols-md="3"
                content-cols-lg="6"
                content-cols-md="7"
              >
                <b-button class="mt-3" type="submit">Save</b-button>
              </k-form-group>
            </div>
          </div>
        </div>
      </k-form>
    </KValidationObserver>
  </div>
</template>
<script>
import { getUnsubscribeReasonType } from "@/api/enum";
import { saveUnsubscribeDetailByEmail, checkEmail } from "@/api/unsubscribe";
import { isPrevent } from "fe-infrastractures";

export default {
  data() {
    return {
      isPrevent,
      reasonTypeOptions: [],
      showForm: false,
      form: {
        email: this.$route.query.email,
        reasonType: 0,
        reason: "",
      },
    };
  },
  props: {},
  created() {
    this.bindBasicInfo();
  },
  mounted() {
    checkEmail(this.form).then((response) => {
      if (response.data.info) {
        let a = this.$alert({
          title: this.$g("const.confirm.confirm"),
          content: response.message,
          okText: this.$g("const.confirm.ok"),
        });
        a.then(() => {
          window.opener = null;
          window.open("", "_self");
          window.close();
        });
      } else {
        this.showForm = true;
      }
    });
  },
  methods: {
    bindBasicInfo() {
      getUnsubscribeReasonType().then((r) => {
        this.reasonTypeOptions = r.data;
      });
    },

    async onSubmit() {
      let isValid = await this.$refs.observer.validate();
      if (isValid) {
        let pmOpt = this.$confirm({
          title: this.$g("const.confirm.confirm"),
          content: this.$g("const.confirm.save"),
          cancelType: "secondary",
          okText: this.$g("const.confirm.saveYes"),
          cancelText: this.$g("const.confirm.no"),
        });
        if (pmOpt == null) {
          return;
        }
        pmOpt.then(async () => {
          saveUnsubscribeDetailByEmail(this.form).then((response) => {
            let a = this.$alert({
              title: this.$g("const.confirm.confirm"),
              content: response.message,
              okText: this.$g("const.confirm.ok"),
            });
            a.then(() => {
              window.opener = null;
              window.open("", "_self");
              window.close();
            });
          });
        });
      }
    },
  },
};
</script>
